import React from "react"

import "./styles.scss"

const Contact = ({ location }) => {
  const langSliced = location.pathname.slice(1, 3)
  return (
    <>
      <section className="contact">
        <div className="mobile-header" />
        <h1 className="contact-title">
          {langSliced === "en" || langSliced === "fr" ? "Contact" : "Контакт"}
        </h1>
        <div className="contact-container">
          <div className="contact-container-infos">
            <div>
              <h2>Email</h2>
              <a
                href="mailto:dashadart75@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                dashadart75@gmail.com
              </a>
            </div>
            <div>
              <h2>Tel</h2>
              <a href="tel:+33665985851" target="_blank" rel="noreferrer">
                +33 665 985 851
              </a>
            </div>
            <div>
              <h2>Instagram</h2>
              <a
                href="https://www.instagram.com/dashad.art"
                target="_blank"
                rel="noreferrer"
              >
                @DashaD.Art
              </a>
            </div>
          </div>
          <div className="contact-container-form">
            {langSliced === "en" && (
              <>
                <p>
                  I would be happy to answer any question you may have. Just
                  please send me a message with your contacts in the form below,
                  and I’ll get back to you ASAP.
                </p>
                <form
                  name="contact-en"
                  method="POST"
                  data-netlify="true"
                  action={`/en/contact/success`}
                >
                  <input type="hidden" name="form-name" value="contact-en" />
                  <p>
                    <label>
                      Name
                      <input type="text" name="name" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Email
                      <input type="email" name="email" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Message
                      <textarea name="message" required></textarea>
                    </label>
                  </p>
                  <p>
                    <button type="submit">Send</button>
                  </p>
                </form>
              </>
            )}
            {langSliced === "fr" && (
              <>
                <p>
                  Pour toute question, n'hésitez pas à remplir le formlaire
                  ci-dessous, je prendrai contact avec vous dans les plus brefs
                  délais.
                </p>
                <form
                  name="contact-fr"
                  method="POST"
                  data-netlify="true"
                  action={`/fr/contact/success`}
                >
                  <input type="hidden" name="form-name" value="contact-fr" />
                  <p>
                    <label>
                      Nom
                      <input type="text" name="name" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Email
                      <input type="email" name="email" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Message
                      <textarea name="message" required></textarea>
                    </label>
                  </p>
                  <p>
                    <button type="submit">Envoyer</button>
                  </p>
                </form>
              </>
            )}
            {langSliced === "ru" && (
              <>
                <p>
                  Если вы хотите задать мне вопрос, можете заполнить данную
                  форму и оставить ваши контакты и я обязательно с вами свяжусь.
                </p>
                <form
                  name="contact-ru"
                  method="POST"
                  data-netlify="true"
                  action={`/ru/contact/success`}
                >
                  <input type="hidden" name="form-name" value="contact-ru" />
                  <p>
                    <label>
                      Имя
                      <input type="text" name="name" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Email
                      <input type="email" name="email" required />
                    </label>
                  </p>
                  <p>
                    <label>
                      Сообщение
                      <textarea name="message" required></textarea>
                    </label>
                  </p>
                  <p>
                    <button type="submit">Отправить</button>
                  </p>
                </form>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
