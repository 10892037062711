import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import Contact from "../components/Contact"

const ContactPage = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)

  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "en" || langSliced === "fr" ? "Contact" : "Контакт"
          }
          image={socialImage}
          path={path}
          lang={langSliced}
        />
        <Contact location={location} />
      </ParallaxProvider>
    </>
  )
}

export default ContactPage
